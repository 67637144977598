import { easeInOutCubic, TimingFunction } from './timing-functions';

export interface TransitionParams {
  duration: number;
  callback(value: number): void;
  timingFunction?: TimingFunction;
  from?: number;
  to?: number;
}
export interface TransitionReturn {
  p: Promise<boolean>;
  stop():void;
}

export function transition(params: TransitionParams) {
  const {
    from = 0,
    to = 1,
    timingFunction = easeInOutCubic,
    duration,
    callback,
  } = params;

  let stop = false;
  const startTime = Date.now();

  const promise = new Promise((resolve) => {
    const step = () => {
      if (stop) {
        resolve(false);
        return;
      }

      const newTime = Date.now();
      const timeDiff = newTime - startTime;
      const timeDiffPercent = timeDiff / duration;

      if (timeDiffPercent >= 1) {
        callback(to);
        resolve(true);
      } else {
        const value = timingFunction(timeDiffPercent);
        callback(from + ((to - from) * value));
        requestAnimationFrame(step);
      }
    }
    step();
  });

  return {
    p: promise,
    stop() {
      stop = true;
    },
  };
}
