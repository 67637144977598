import { defineStore } from 'pinia';
import { ref } from 'vue';
import { v4 as uuid } from 'uuid';

export interface ClockState {
  now: number,
}

interface ScheduledAction {
  id: string,
  action(): void,
  time: number,
}

export const useClockStore = defineStore('clock', () => {
  const state = ref<ClockState>({
    now: Date.now(),
  });

  let scheduledActions: ScheduledAction[] = [];

  function checkSchedulesAction() {
    let endIndex = 0;
    for (let i = 0; i < scheduledActions.length; ++i) {
      if (scheduledActions[i].time > Date.now()) {
        endIndex = i;
        break;
      }
    }
    if (endIndex > 0) {
      const actions = scheduledActions.splice(0, endIndex);
      actions.forEach(({action}) => action());
    }
  }
  function scheduleAction(time: number, action: () => void): string {
    const id = uuid();
    if (time > Date.now()){
      scheduledActions.push({
        id,
        time,
        action,
      });
      scheduledActions.sort((a, b) => a.time - b.time);
    } else {
      action();
    }
    return id;
  }
  function removeScheduledAction(id: string) {
    scheduledActions = scheduledActions.filter((a) => a.id !== id);
  }

  setInterval(() => {
    state.value.now = Date.now();
    checkSchedulesAction();
  }, 500);

  return {
    state,
    scheduleAction,
    removeScheduledAction,
    removeAllScheduledActions() {
      scheduledActions = [];
    }
  };
})
