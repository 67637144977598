import * as Joi from 'joi';
import { EngineFuelList } from '../../types/engine-fuel';

export const carCreateUpdateSchema = Joi.object({
  vin_code: Joi.string().allow(null, '').max(20),
  registration_plate: Joi.string().allow(null, '').max(40),
  manufacture_date: Joi.string().min(4).max(4).allow('', null),
  engine_fuel: Joi.number().required().allow(...EngineFuelList),
  lpg: Joi.boolean(),
  engine_displacement: Joi.number().allow(null).max(99.99),
  notes: Joi.string().max(2000).allow('', null),
  car_model_id: Joi.number().required(),
  client_id: Joi.number().required(),
  files: Joi.array().items(Joi.number()),
});
