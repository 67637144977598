import { CustomValidator } from 'joi';
import { dateToTimestamp } from 'client/src/helpers/datetime';

function checkDateFormat(value: string) {
  const path = value.split('.');

  if (path.length === 3) {
    const [day, month, year] = path;
    if (day.length === 2 && month.length === 2 && year.length === 4) {
      const dayV = Number(day);
      const monthV = Number(month);
      const yearV = Number(year);

      if (![dayV, monthV, yearV].some((v) => isNaN(v) || v <= 0) && monthV <= 12) {
        const checkDayDate = new Date(Date.UTC(yearV, monthV, 0));
        const dayMax = checkDayDate.getUTCDate();
        if (dayV <= dayMax) {
          return true;
        }
      }
    }
  }
  return false;
}

export const dateFormat: CustomValidator = (value: any, opts) => {
  if (typeof value === 'string' && checkDateFormat(value)) {
    return value;
  }
  return opts.error('date.invalidformat');
};

export const dateMaxNow: CustomValidator = (value: any, opts) => {
  if (typeof value === 'string' && checkDateFormat(value)) {
    const timestamp = dateToTimestamp(value) as number;
    if (timestamp > Date.now()) {
      return opts.error('date.maxnow');
    }
  }
  return value;
};

export const dateMinNow: CustomValidator = (value: any, opts) => {
  if (typeof value === 'string' && checkDateFormat(value)) {
    const timestamp = dateToTimestamp(value) as number;
    if (timestamp < Date.now()) {
      return opts.error('date.minnow');
    }
  }
  return value;
};
