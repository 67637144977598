import { defineStore } from 'pinia';
import { CrudService } from '../services/crud.service';
import { Ref, ref } from 'vue';
import { BaseResource } from '@shared/resources/base.resource';
import Validator from '@/libs/validator/validator';

export interface CrudStoreParams<CreateDTO, UpdateDTO, Resource extends BaseResource> {
  storeId: string,
  service: CrudService<CreateDTO, UpdateDTO, Resource>
}

export function defineCrudStore<CreateDTO, UpdateDTO, Resource extends BaseResource>(params: CrudStoreParams<CreateDTO, UpdateDTO, Resource>) {
  return defineStore(params.storeId, () => {
    const data: Ref<Resource[]> = ref([]);
    const isInit = ref<boolean>(false);
    const isLoad = ref<boolean>(false);

    const { service } = params;

    function loadData() {
      if (isLoad.value) return;
      isLoad.value = true;

      service.getAll().send().then((response) => {
        data.value = response.data;
      }).finally(() => {
        isLoad.value = false;
        isInit.value = true;
      })
    }

    function initData() {
      if (isLoad.value || isInit.value) return;
      return loadData();
    }

    function add(createdItem: CreateDTO, validator?: Validator) {
      isLoad.value = true;
      return service
        .add(createdItem)
        .useValidator(validator)
        .send()
        .then((response) => {
          data.value = [...data.value, response.data];
          return response.data;
        }).finally(() => {
          isLoad.value = false;
        });
    }

    function update(id: number, updatedItem: UpdateDTO, validator?: Validator) {
      isLoad.value = true;
      return service
        .update(id, updatedItem)
        .useValidator(validator)
        .send()
        .then((response) => {
          data.value = data.value.map((item) => {
            if (item.id === response.data.id) {
              return response.data;
            }
            return item;
          });
          return response.data;
        }).finally(() => {
          isLoad.value = false;
        });
    }

    function remove(id: number) {
      isLoad.value = true;
      return service.remove(id).send().then(() => {
        data.value = data.value.filter((item) => {
          return item.id !== id;
        });
      }).finally(() => {
        isLoad.value = false;
      });
    }

    return {
      data,
      isInit,
      isLoad,

      loadData,
      initData,
      add,
      update,
      remove,
    };
  });
}
