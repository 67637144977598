import { createI18n } from 'vue-i18n';
import messages, { Lang } from './messages';
import { loadLang } from './local-storage';

export default createI18n({
  legacy: false,
  locale: loadLang(Lang.UA),
  fallbackLocale: Lang.EN,
  messages,
});
