import { onUnmounted } from 'vue';

const PREFIX = '!';
const SUFFIX = '!';
const FINISH_KEY = 'Enter';
const SCANNER_MAX_DELAY = 1200;

enum Phase {
  PENDING,
  ON_SCAN,
}

const INPUT_WRITABLE_TYPES = [
  'email',
  'text',
  'tel',
  'number',
  'url',
];

function getActiveElement(): HTMLTextAreaElement | HTMLInputElement | void {
  if (document.activeElement) {
    const active = document.activeElement;
    if (active instanceof HTMLTextAreaElement || active instanceof HTMLInputElement && INPUT_WRITABLE_TYPES.includes(active.type)) {
      return active;
    }
  }
}

export type UseScannerCallback = (scanned: string) => void;

let callbacks: UseScannerCallback[] = [];
let destroyScanner: (() => void) | undefined;

function callCallback(scanned: string) {
  console.log("SCAN", scanned);
  const callback = callbacks[callbacks.length - 1];
  if (callback) {
    callback(scanned);
  }
}

function initScanner(): () => void {
  let phase: Phase = Phase.PENDING;
  let scanned: string = '';

  let timer: any = null;

  function returnToActive() {
    const active = getActiveElement();
    if (active) {
      const { selectionStart, selectionEnd, value } = active;
      if (selectionStart !== null && selectionEnd !== null) {
        active.value = value.slice(0, selectionStart) + scanned + value.slice(selectionEnd);
        active.selectionStart = selectionStart + scanned.length;
        active.selectionEnd = selectionStart + scanned.length;
      } else {
        active.value = `${value}${scanned}`;
      }

      active.dispatchEvent(new Event('input'));
    }
  }

  function addToScanned(key: string) {
    scanned += key;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      returnToActive();
      phase = Phase.PENDING;
    }, SCANNER_MAX_DELAY);
  }

  const keydownListener = (e: KeyboardEvent) => {
    const key = e.key;
    switch(phase) {
      case Phase.PENDING: {
        scanned = '';
        if (key.length === 1 && key === PREFIX) {
          phase = Phase.ON_SCAN;
          addToScanned(key);

          e.preventDefault();
        }

        break;
      }
      case Phase.ON_SCAN: {
        if (key === FINISH_KEY) {
          timer && clearTimeout(timer);
          timer = null;
          phase = Phase.PENDING;

          if (scanned[scanned.length - 1] === SUFFIX) {
            callCallback(scanned.slice(1, -1));
          } else {
            returnToActive();
          }
          e.preventDefault();
        } else if (key.length === 1) {
          addToScanned(key);
          e.preventDefault();
        }
        break;
      }
    }
  }
  document.addEventListener('keydown', keydownListener);
  return () => {
    document.removeEventListener('keydown', keydownListener);
  }
}

function addCallback(callback: UseScannerCallback) {
  if (callbacks.length === 0) {
    const destroy = initScanner();
    destroyScanner = () => {
      destroy();
      destroyScanner = undefined;
    }
  }

  callbacks.push(callback);
}
function removeCallback(callback: UseScannerCallback) {
  callbacks = callbacks.filter((c) => c !== callback);

  if (callbacks.length === 0) {
    destroyScanner && destroyScanner();
  }
}

export interface UseScannerParams {}
export function useScanner(callback: UseScannerCallback, params?: UseScannerParams) {
  addCallback(callback);

  onUnmounted(() => {
    removeCallback(callback);
  });
}
