import { ApiService } from '@/libs/api-service/api-service';
import axios from 'axios';

let token: string | undefined;
export function setToken(tokenValue?: string) {
  token = tokenValue;
}

export class ApiAuthService extends ApiService {
  protected static getAxiosInstance(){
    return axios.create({
      headers: {
        Authorization: `bearer ${token}`
      },
    });
  }
}
