import * as Joi from 'joi';
import { QtyUnitList } from '../../types/qty-unit';
import { MAX_DECIMAL } from '../../constants/max-decimal';
import { CurrencyList } from '../../types/currency';

export const itemCreateUpdateSchema = Joi.object({
  name: Joi.string().required().min(2).max(500),
  short_name: Joi.string().allow(null, '').min(2).max(80),
  print_name: Joi.string().allow(null, '').min(2).max(255),
  sale_price: Joi.number().required().min(0).unsafe().max(MAX_DECIMAL),
  purchase_price: Joi.number().min(0).unsafe().max(MAX_DECIMAL).when('sale_price_auto', {
    is: Joi.any().valid(true),
    then: Joi.number().required(),
    otherwise: Joi.number().allow(null, ''),
  }),
  sale_price_currency: Joi.number().allow(...CurrencyList),
  purchase_price_currency: Joi.number().allow(...CurrencyList),
  sale_price_auto: Joi.boolean().required(),
  sale_price_auto_percentage: Joi.boolean().required(),
  qty: Joi.number().required().min(0).unsafe().max(MAX_DECIMAL),
  min_stock_qty: Joi.number().allow(null, '').min(0).unsafe().max(MAX_DECIMAL),
  max_stock_qty: Joi.number().allow(null, '').min(0).unsafe().max(MAX_DECIMAL).when('min_stock_qty', {
    is: Joi.any().valid(null, ''),
    otherwise: Joi.number().greater(Joi.ref('min_stock_qty')),
  }),
  barcode: Joi.string().max(255).allow('', null),
  qty_type: Joi.number().allow(...QtyUnitList).required(),
  category_id: Joi.number().allow(null),
  item_company_id: Joi.number().allow(null),
  notes: Joi.string().max(2000).allow('', null),
  files: Joi.array().items(Joi.number()),
});
