import { computed, ref, Ref } from 'vue';
import { Row } from '../../types';

export interface UseSelectableParams {
  rows: Ref<Row[]>,
}
export const useSelectable = (params: UseSelectableParams) => {
  const selectedIds = ref<Array<string | number>>([]);
  const isAllSelected = computed<boolean>(() => {
    return !params.rows.value.some(({id}) => !selectedIds.value.includes(id));
  });

  const toggleSelect = (row: Row) => {
    if (selectedIds.value.includes(row.id)) {
      selectedIds.value = selectedIds.value.filter((id) => row.id !== id);
    } else {
      selectedIds.value.push(row.id);
    }
  };
  const toggleAllRows = () => {
    if (isAllSelected.value) {
      selectedIds.value = [];
    } else {
      selectedIds.value = params.rows.value.map(({id}) => id);
    }
  };

  return {
    selectedIds,
    isAllSelected,
    toggleSelect,
    toggleAllRows,
  };
};
