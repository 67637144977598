import { CrudService } from './crud.service';
import { ItemCreateUpdateDto as DTO } from '@shared/dto/item';
import { ItemResource as Res } from '@shared/resources/item';

export class ItemService extends CrudService<DTO, DTO, Res> {
  protected pathPrefix(): string {
    return 'items';
  }

  getByBarcode(barcode: string) {
    return this.createGet<Res>(`barcode/${barcode}`);
  }
}
