import { Modifier } from '@popperjs/core';
import { createPopper, Placement } from '@popperjs/core';

export const sameWidthModifier = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    const reference = state.elements.reference as HTMLElement;
    state.elements.popper.style.width = `${reference.offsetWidth}px`;
  }
} as Modifier<'sameWidth', any>;

export function popperDropdown (elem: HTMLElement, dropdown: HTMLElement) {
  return createPopper(elem, dropdown, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 5]
        },
      },
      sameWidthModifier,
    ]
  })
}

export function popperTooltip(elem: HTMLElement, tooltip: HTMLElement, placement: Placement = 'top') {
  const arrow = tooltip.querySelector('.arrow');
  return createPopper(elem, tooltip, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10]
        }
      },
      {
        name: 'arrow',
        options: {
          element: arrow,
        }
      }
    ]
  });
}
