export type NumberAllowedType = string | number | null | undefined;

export function toNumberFormat(value: NumberAllowedType): number {
  const numberValue = Number(value);
  return isNaN(numberValue) ? 0 : numberValue;
}

type DataFunction = (data: Record<string, any>) => any;
interface SafeSumParams {
  priceKey: string | DataFunction;
  qtyKey: string | DataFunction;
}
export function safeSum(items: Array<Record<string, any>>, params?: SafeSumParams): number {
  const {
    priceKey = 'price',
    qtyKey = 'qty',
  } = params || {};

  const getValue = (data: Record<string, any>, key: string|DataFunction) => {
    if (typeof key === 'function') return key(data);
    return data[key];
  }

  let sum = 0;
  items.forEach((item) => {
    const qty = Math.round(toNumberFormat(getValue(item, qtyKey)) * 100);
    const price = Math.round(toNumberFormat(getValue(item, priceKey)) * 100);

    sum += Math.round((qty * price) / 100);
  });
  return sum / 100;
}

function isEmpty(value: NumberAllowedType): boolean {
  return value === null || value === undefined || (typeof value === 'string' && value.trim().length === 0);
}

export function coinsFormat(value: NumberAllowedType, zeroEmpty: boolean = false): string {
  if (!zeroEmpty && isEmpty(value)) return '';

  const numberValue = toNumberFormat(value);
  const [w, f = '00'] = String(Math.round(numberValue * 100) / 100).split('.');
  return `${w}.${f.slice(0, 2).padEnd(2, '0')}`;
}

export function floatFormat(value: NumberAllowedType, zeroEmpty: boolean = false) {
  if (!zeroEmpty && isEmpty(value)) return '';

  const numberValue = toNumberFormat(value);
  return String(numberValue);
}
