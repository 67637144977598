import { inject, onBeforeUnmount, provide } from 'vue';
import Validator, { ValidatorParams } from '../validator';
import { validatorKey } from '../injection-keys';

export const useValidator = (params: ValidatorParams = {}) => {
  const validator = new Validator(params);
  const parentValidator = inject(validatorKey, undefined);
  if (parentValidator){
    parentValidator.addChild(validator);
    onBeforeUnmount(() => parentValidator.removeChild(validator));
  }
  provide(validatorKey, validator);

  return validator;
}
