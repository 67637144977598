import { useCurrencyStore } from '../stores/currency';
import { coinsFormat, toNumberFormat } from '@shared/helpers/data-converters/number-converters';
import { Currency } from '@shared/types/currency';

export interface AutoSalePriceOptions {
  sale_price: string;
  sale_price_currency: Currency,
  sale_price_auto: boolean;
  sale_price_auto_percentage: boolean;
  purchase_price: string;
  purchase_price_currency: Currency;
}

export function useAutoSalePrice() {
  const currenciesStore = useCurrencyStore();

  return (opts: AutoSalePriceOptions) => {
    const {
      sale_price_currency,
      purchase_price,
      sale_price,
      purchase_price_currency,
      sale_price_auto,
      sale_price_auto_percentage,
    } = opts;

    const sale = toNumberFormat(sale_price);

    if (!sale_price_auto) {
      return sale_price ? currenciesStore.exchange(sale, sale_price_currency) : '-';
    }

    if (!sale_price || !purchase_price) {
      return '-';
    }

    const purchase = toNumberFormat(purchase_price);

    if (sale_price_auto_percentage) {
      const price = purchase + (purchase / 100 * sale);
      return coinsFormat(currenciesStore.exchange(price, purchase_price_currency));
    }

    const price = currenciesStore.exchange(purchase, purchase_price_currency)
      + currenciesStore.exchange(sale, sale_price_currency);
    return coinsFormat(price);
  }
}
