import { computed, onBeforeMount, onBeforeUnmount, ref } from 'vue';

const clientWidth = ref<number>(0);
const clientHeight = ref<number>(0);
const innerWidth = ref<number>(0);
const innerHeight = ref<number>(0);
const documentHeight = ref<number>(0);

function resizeHandler() {
  clientWidth.value = document.documentElement.clientWidth;
  clientHeight.value = document.documentElement.clientHeight;

  innerHeight.value = window.innerHeight;
  innerWidth.value = window.innerWidth;

  documentHeight.value = Math.max(
    document.body.scrollHeight, document.documentElement.scrollHeight,
    document.body.offsetHeight, document.documentElement.offsetHeight,
    document.body.clientHeight, document.documentElement.clientHeight
  );
}

let subscribersCount = 0;
function subscribe() {
  if (subscribersCount === 0) {
    resizeHandler();
    window.addEventListener('resize', resizeHandler);
    window.addEventListener('orientationchange', resizeHandler);
  }
  subscribersCount += 1;
}
function unsubscribe() {
   subscribersCount -= 1;
   if (subscribersCount === 0) {
     window.removeEventListener('resize', resizeHandler);
     window.removeEventListener('orientationchange', resizeHandler);
   }
}

export function useWindowSizes() {
  onBeforeMount(subscribe);
  onBeforeUnmount(unsubscribe);

  return {
    clientWidth: computed(() => clientWidth.value),
    clientHeight: computed(() => clientHeight.value),
    innerWidth: computed(() => innerWidth.value),
    innerHeight: computed(() => innerHeight.value),
    documentHeight: computed(() => documentHeight.value),
  };
}
