import { nextTick, onBeforeUnmount, onMounted, ref, Ref, watch, watchEffect } from 'vue';

export const useRefElementWidth = (target: Ref<HTMLElement|undefined>) => {
  const width = ref<number>(0)
  async function resizeFunction() {
    await nextTick()
    const element = target.value;
    if (element){
      width.value = Math.round(element.getBoundingClientRect().width);
    }
  }
  const resizeObserver = new ResizeObserver(resizeFunction);

  onMounted(() => {
    resizeFunction();
    const elem = target.value;
    if (elem) {
      resizeObserver.observe(elem);
    }
  });
  window.addEventListener('resize', resizeFunction);
  window.addEventListener('orientationchange', resizeFunction);
  onBeforeUnmount(() => {
    resizeObserver.disconnect();
    window.removeEventListener('resize', resizeFunction);
    window.removeEventListener('orientationchange', resizeFunction);
  });
  watchEffect(resizeFunction);

  watch(target, (value) => {
    resizeObserver.disconnect();
    if (value) {
      resizeObserver.observe(value);
    }
  });

  return width;
};
