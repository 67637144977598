export const pathJoin = (...args: string[]): string => {
  return args.map((part, i) => {
    if (i === 0) {
      return part.trim().replace(/[\/]*$/g, '')
    } else {
      return part.trim().replace(/(^[\/]*|[\/]*$)/g, '')
    }
  }).filter(x=>x.length).join('/')
};

export const checkPathWithin = (path: string, withinPath: string, exact: boolean = false): boolean => {
  const pathParts = path.split('/').filter((v) => v.length > 0);
  const withinPathParts = withinPath.split('/').filter(v => v.length > 0);

  if (pathParts.length > withinPathParts.length) return false;
  if (exact) {
    return pathParts.length === withinPathParts.length
      && !pathParts.some((ap, i) => ap !== withinPathParts[i])
  }
  return !pathParts.some((ap, i) =>  ap !== withinPathParts[i])
}