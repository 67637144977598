import { computed, ref, Ref, watch } from 'vue';
import { Option, SelectDataManager } from '../types';
import { get } from 'lodash';

export interface SelectClientDataManagerParams {
  data: Ref<Option[]>;
  perPage?: number;
  selected?: Ref<Option[]|Option|undefined>;
  hideSelected?: boolean;
  searchBy?: string | string[];
  customSearch?(option: Option, search: string): boolean;
  idKey?: string;
}
export function useSelectClientDataManager(params: SelectClientDataManagerParams): SelectDataManager {
  const {
    data,
    selected = ref([]),
    customSearch,
    searchBy = 'name',
    perPage = 30,
    hideSelected = false,
    idKey = 'id',
  } = params;
  const searchValue = ref<string>('');
  let page = 1;
  watch(searchValue, () => {
    page = 1;
  });

  const filteredData = computed<Option[]>(() => {
    if (hideSelected) {
      const selectedValue = selected.value;
      let ids: any[] = [];
      if (Array.isArray(selectedValue)) {
        ids = selectedValue.map((option) => option[idKey]);
      } else {
        ids = selectedValue ? [selectedValue[idKey]] : [];
      }
      return data.value.filter((option) => {
        return !ids.includes(option[idKey]);
      });
    }
    return data.value;
  });
  const searchCallback = computed(() => {
    if (customSearch) return customSearch;

    const searchByArray = Array.isArray(searchBy) ? searchBy : [searchBy];

    return (option: Option, search: string) => searchByArray.some((searchByItem) => {
      const value = get(option, searchByItem).toLowerCase();
      return value.includes(search);
    })
  })
  const searchedData = computed<Option[]>(() => {
    const searchValueTrimmed = searchValue.value.trim().toLowerCase();

    if (searchValueTrimmed.length > 0) {
      return filteredData.value.filter((option) => {
        return searchCallback.value(option, searchValueTrimmed);
      });
    }
    return filteredData.value;
  });

  return {
    searchValue,
    fetchNextOptions() {
      const options = searchedData.value.slice(0, page * perPage);
      page += 1;
      return Promise.resolve(options);
    }
  }
}
