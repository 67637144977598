import { computed } from 'vue';
import { NAVIGATION_TYPES, NavigationNode } from '@components/navigation/types';
import { useI18n } from 'vue-i18n';

export function useNavigationNodes() {
  const { t } = useI18n();

  const navigationNodes = computed<NavigationNode[]>(() => {
    return [
      {
        name: 'home',
        label: t('navigation.home'),
        icon: 'home',
        path: '/',
        exact: true,
        type: NAVIGATION_TYPES.node,
      },
      {
        name: 'orders',
        label: t('navigation.orders'),
        icon: 'clipboard-list',
        path: '/orders',
        type: NAVIGATION_TYPES.node,
      },
      {
        name: 'storage',
        label: t('navigation.storage'),
        icon: 'boxes',
        path: '/storage',
        type: NAVIGATION_TYPES.node,
      },
      {
        name: 'clients',
        label: t('navigation.clients'),
        icon: 'users',
        path: '/clients',
        type: NAVIGATION_TYPES.node,
        nodes: [
          {
            path: '',
            exact: true,
            name: 'items',
            label: t('navigation.common.list'),
            type: NAVIGATION_TYPES.node,
          },
          {
            path: 'cars',
            name: 'cars',
            label: t('navigation.client-cars'),
            type: NAVIGATION_TYPES.node,
          },
        ],
      },
      {
        name: 'dictionary',
        label: t('navigation.dictionary'),
        type: NAVIGATION_TYPES.node,
        path: '/dictionary',
        icon: 'database',
        nodes: [
          {
            name: 'categories',
            label: t('navigation.categories'),
            path: 'categories',
            type: NAVIGATION_TYPES.node,
          },
          {
            name: 'item-companies',
            label: t('navigation.item-companies'),
            path: 'item-companies',
            type: NAVIGATION_TYPES.node,
          },
          {
            name: 'car_models',
            label: t('navigation.car_models'),
            path: 'models',
            type: NAVIGATION_TYPES.node,
          },
          {
            name: 'car_brands',
            label: t('navigation.car_brands'),
            path: 'brands',
            type: NAVIGATION_TYPES.node,
          },
          {
            name: 'repair_types',
            label: t('navigation.repair_types'),
            path: 'repair-types',
            type: NAVIGATION_TYPES.node,
          }
        ]
      }
    ];
  });

  return {
    navigationNodes,
  };
}
