import {
  Filters,
  PaginationData,
  PaginationDetails,
  Row,
  SortDetails,
  SortOrder,
  TableDataManager,
} from '../types';

import {computed, ref, Ref, watch} from 'vue';
import _ from 'lodash';

type SearchOverride = (rows: Row[], search: string) => Row[];
const search = (rows: Row[], search: string, searchBy?: string[], override?: SearchOverride) => {
  if (override) return override(rows, search);
  if (searchBy){
    const searchPrepared = search.toLowerCase().trim();
    return rows.filter((row) => {
      return searchBy.some((col) => _.get(row, col).toLowerCase().trim().indexOf(searchPrepared) === 0);
    });
  }
  return rows;
};

type FilterOverride = (rows: Row[], filters: Filters) => Row[];
const filter = (rows: Row[], filters: Filters, override?: FilterOverride) => {
  if (override) return override(rows, filters);

  const filterCallbacks = Object.keys(filters).reduce((result, key) => {
    const value = filters[key];
    if (value === null) return result;
    if (Array.isArray(value)){
      if (value.length === 0) return result;

      result[key] = (row: Row) => value.includes(_.get(row, key));
    } else {
      result[key] = (row: Row) => {
        return String(_.get(row, key)) === value;
      };
    }
    return result;
  }, {} as Record<string, (row: Row) => boolean>);

  if (Object.keys(filterCallbacks).length === 0) return rows;
  return rows.filter((row) => {
    return !Object.keys(filterCallbacks).some((key) => !filterCallbacks[key](row))
  });
};

type SortOverride = (rows: Row[], sortDetails: SortDetails) => Row[];
const sort = (rows: Row[], sortDetails: SortDetails, override?: SortOverride) => {
  if (override) return override(rows, sortDetails);

  const comparisonFunc = (key: string, a: Row, b: Row) => {
    if (_.get(a, key) > _.get(b, key)) return 1;
    if (_.get(b, key) > _.get(a, key)) return -1;
    return 0;
  }

  const direction = sortDetails.order === SortOrder.ASC ? 1 : -1;
  return [...rows].sort((a,b) => comparisonFunc(sortDetails.column, a, b) * direction);
};

type PaginateOverride = (rows: Row[], paginateDetails: PaginationDetails) => Row[];
const paginate = (rows: Row[], paginateDetails: PaginationDetails, override?: PaginateOverride) => {
  if (override) return override(rows, paginateDetails);

  const { page, perPage } = paginateDetails;
  return rows.slice((page - 1) * perPage, page * perPage);
};

export interface TableClientDataManagerParams {
  providedData: Ref<Row[]>;
  loading?: Ref<boolean>;
  searchBy?: string[];
  override?: {
    search?: SearchOverride;
    filter?: FilterOverride;
    sort?: SortOverride;
    paginate?: PaginateOverride;
  };
}

export class TableClientDataManager implements TableDataManager {
  private override?: TableClientDataManagerParams['override'];
  private searchBy?: string[];
  private providedData: Ref<Row[]>;
  private loading: Ref<boolean>;

  private paginationData: Ref<PaginationData|undefined>;
  private search: Ref<string>;
  private sortOrder: Ref<SortDetails|undefined>;
  private filters: Ref<Filters|undefined>;

  private searchedData: Ref<Row[]>;
  private filteredData: Ref<Row[]>;
  private sortedData: Ref<Row[]>;
  private paginatedData: Ref<Row[]>;

  constructor(params: TableClientDataManagerParams) {
    this.override = params.override;
    this.providedData = params.providedData;
    this.searchBy = params.searchBy;
    this.loading = params?.loading || ref(false);

    this.search = ref('');
    this.paginationData = ref();
    this.sortOrder = ref();
    this.filters = ref();

    this.filteredData = computed(() => {
      if (this.filters.value){
        return filter(this.providedData.value, this.filters.value, this.override?.filter);
      }
      return this.providedData.value;
    });
    this.searchedData = computed(() => {
      if (this.search.value.trim().length > 0){
        return search(this.filteredData.value, this.search.value, this.searchBy, this.override?.search);
      }
      return this.filteredData.value;
    });
    this.sortedData = computed(() => {
      if (this.sortOrder.value){
        return sort(this.searchedData.value, this.sortOrder.value, this.override?.sort);
      }
      return this.searchedData.value;
    });
    this.paginatedData = computed(() => {
      if (this.paginationData.value){
        return  paginate(this.sortedData.value, this.paginationData.value, this.override?.paginate);
      }
      return this.sortedData.value;
    });

    this.initWatchers();
    this.setPagination({
      page: 1,
      perPage: 15,
    });
  }

  initWatchers(){
    watch(this.sortedData, () => {
      this.setPagination(this.paginationData.value);
    });
  }
  
  getPagesCount(perPage: number = 0){
    const rows = this.sortedData.value.length;
    return perPage > 0 ? (Math.ceil(rows / perPage) || 1) : 1;
  }

  isLoading(){
    return this.loading.value;
  }

  setFilters(filters?: Filters){
    this.filters.value = filters;
  }

  setSearch(search: string = ''){
    this.search.value = search;
  }

  setSortOrder(order?: SortDetails){
    this.sortOrder.value = order;
  }

  setPage(page: number){
    const data = this.paginationData.value;
    if (data){
      this.setPagination({
        page,
        perPage: data.perPage,
      })
    } else {
      this.setPagination({
        page,
        perPage: 15,
      })
    }
  }
  
  setPagination(details?: PaginationDetails){
    if (details){
      const pageCount = this.getPagesCount(details.perPage);
      const page = details.page > pageCount ? pageCount : details.page;
      this.paginationData.value = {
        page,
        pageCount,
        perPage: details.perPage,
        rowsCount: this.searchedData.value.length,
      }
    } else {
      this.paginationData.value = undefined;
    }
  }

  getData(){
    return this.paginatedData.value;
  }
  getPaginationData(){
    return this.paginationData.value;
  }
  getFilters(){
    return this.filters.value;
  }
  getSearch(){
    return this.search.value;
  }
  getSortOrder(){
    return this.sortOrder.value;
  }
}
