export enum EngineFuel {
  petrol = 1,
  diesel = 2,
  electric = 3,
  gas = 4,
  hybrid = 5,
}

export const EngineFuelNameMap = {
  [EngineFuel.petrol]: 'petrol',
  [EngineFuel.diesel]: 'diesel',
  [EngineFuel.electric]: 'electric',
  [EngineFuel.gas]: 'gas',
  [EngineFuel.hybrid]: 'hybrid',
};

export const EngineFuelList = [
  EngineFuel.petrol,
  EngineFuel.diesel,
  EngineFuel.electric,
  EngineFuel.gas,
  EngineFuel.hybrid,
];
