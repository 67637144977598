// DATE FORMAT 'DD.MM.YYYY'

function padV(value: string | number): string {
  return String(value).padStart(2, '0');
}

export function getLocalDate(): string {
  const date = new Date();

  return `${padV(date.getDate())}.${padV(date.getMonth() + 1)}.${date.getFullYear()}`;
}

export function timestampToDate(value?: number) {
  if (!value) return '';
  const date = new Date(value);

  return `${padV(date.getUTCDate())}.${padV(date.getUTCMonth() + 1)}.${date.getUTCFullYear()}`;
}

export function dateToTimestamp(value?: string): number | undefined {
  const parts = (value || '').split('.');
  if (parts.length >= 3) {
    const [date, month, year] = parts;
    if (date.length === 2 && month.length === 2 && year.length === 4) {
      const dateV = Number(date);
      const monthV = Number(month);
      const yearV = Number(year);

      if (!isNaN(dateV) && !isNaN(monthV) && !isNaN(yearV)) {
        return Date.UTC(yearV, monthV - 1, dateV);
      }
    }
  }
  return undefined;
}
