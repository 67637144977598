import { onBeforeUnmount, onMounted, Ref, watch } from 'vue';

interface UseMutationObserverParams {
  target: Ref<Element | undefined>;
  callback(): void;
  handleAttributes?: boolean;
}

export function useMutationObserver(params: UseMutationObserverParams) {
  const { target, callback, handleAttributes = false } = params;
  const observer = new MutationObserver(callback);

  function startObserve(elem: Element) {
    observer.disconnect();
    observer.observe(elem, {
      childList: true,
      subtree: true,
      characterData: true,
      attributes: handleAttributes,
    });
  }

  onMounted(() => {
    const targetElem = target.value;
    if (targetElem) {
      startObserve(targetElem);
    }
  });
  watch(target, (value) => {
    if (!value) {
      observer.disconnect();
    } else {
      startObserve(value);
    }
  });
  onBeforeUnmount(() => {
    observer.disconnect();
  });
}
