import { defineStore } from 'pinia';
import { Component, nextTick, ref } from 'vue';

interface PrintParams {
  component: Component;
  props?: Record<string, any>;
}

export const usePrint = defineStore('print', () => {
  const data = ref<PrintParams|null>(null);
  async function print(params: PrintParams) {
    data.value = params;
    await nextTick();
    window.print();
    data.value = null;
  }

  return {
    data,
    print,
  };
});
