import axios, { AxiosRequestConfig, Method } from 'axios';
import qs from 'qs';
import { ApiRequest } from './api-request';
import { pathJoin } from '@/helpers/path';
import {Map, AxiosRequestCallback} from './types';

const GLOBAL_API_PREFIX = '/api';

export interface CreateRequestOptions <P = Map, D = Map>{
  data?: D;
  params?: P;
}
export interface ApiServiceParams{
}

export class ApiService {

  constructor(params: ApiServiceParams = {}) {
  }

  protected pathPrefix(): string {
    return '';
  }

  protected static getAxiosInstance(){
    return axios.create();
  }

  public createRequest<Response = any>(path: string, method: Method, options?: AxiosRequestConfig) {
    const prefix = this.pathPrefix();
    const axiosRequestCallback: AxiosRequestCallback = () => (this.constructor as typeof ApiService).getAxiosInstance().request({
      method,
      url: pathJoin(GLOBAL_API_PREFIX, prefix ? pathJoin(prefix, path) : path),
      paramsSerializer: (params) => qs.stringify(params),
      ...options,
    });

    const request = new ApiRequest<Response>({
      axiosRequestCallback,
    });

    return request;
  }

  public createGet<Response = any, P = Map>(path: string, params?: P, options?: AxiosRequestConfig){
    return this.createRequest<Response>(path, 'GET', {
      ...options,
      params,
    });
  }

  public createPost<Response = any, D = Map, P = Map>(path: string, data?: D, params?: P, options?: AxiosRequestConfig){
    return this.createRequest<Response>(path, 'POST', {
      ...options,
      params,
      data,
    });
  }

  public createPut<Response = any, D = Map, P = Map>(path: string, data?: D, params?: P, options?: AxiosRequestConfig){
    return this.createRequest<Response>(path, 'PUT', {
      ...options,
      params,
      data,
    });
  }


  public createDelete<Response = any, P = Map>(path: string, params?: P, options?: AxiosRequestConfig){
    return this.createRequest<Response>(path, 'DELETE', {
      ...options,
      params,
    });
  }
}
