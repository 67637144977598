import { Filters, PaginationData, PaginationDetails, Row, SortDetails, TableDataManager } from '../types';
import { ref, Ref } from 'vue';
import { ApiAuthService } from '@/services/api-auth-service';
import { FilterFlow } from '@shared/types/filter-flow';
import TableCrudResource from '@shared/resources/table-crud.resource';
import { TableCrudRequestDto } from '@shared/dto/table-crud-request.dto';
import { cloneDeep } from 'lodash';

const DEBOUNCE_TIMEOUT = 300;

export interface TableDataRequestBody {
  page?: number;
  perPage?: number;
  search?: string;
  filters?: Filters;
  sortOrder?: SortDetails;
}

export interface TableServerDataManagerParams {
  path: string;
  pagination?: PaginationDetails;
  filtersFlow?: Record<string, FilterFlow>;
  initialSortOrder?: SortDetails;
}

export class TableServerDataManager implements TableDataManager {
  private data: Ref<Row[]>;
  private paginationData: Ref<PaginationData|undefined>;
  private search: Ref<string>;
  private sortOrder: Ref<SortDetails|undefined>;
  private filters: Ref<Filters|undefined>;
  private loading: Ref<boolean>;
  private debounceTimer: any;
  private nextRequestData?: TableDataRequestBody;

  constructor(protected params: TableServerDataManagerParams) {
    this.data = ref([]);
    this.paginationData = ref(undefined);
    this.search = ref('');
    this.sortOrder = ref(params.initialSortOrder);
    this.filters = ref(undefined);
    this.loading = ref(false);

    this.request({
      page: params.pagination?.page,
      perPage: params.pagination?.perPage,
    });
  }

  getData() {
    return this.data.value;
  }
  getPaginationData() {
    return this.paginationData.value;
  }
  getFilters() {
    return this.filters.value;
  }
  getSortOrder() {
    return this.sortOrder.value;
  }
  getSearch() {
    return this.search.value;
  }
  isLoading() {
    return this.loading.value;
  }

  generateRequestData(): TableDataRequestBody {
    return {
      sortOrder: cloneDeep(this.sortOrder.value),
      filters: cloneDeep(this.filters.value),
      search: this.search.value,
      page: this.paginationData.value?.page,
      perPage: this.paginationData.value?.perPage,
    };
  }

  requestTableData(requestData: TableDataRequestBody): Promise<TableCrudResource<any>> {
    this.loading.value = true;

    function getFilterValues(filter: any|any[]): string[] {
      if (filter === undefined) return [];
      if (Array.isArray(filter)) return filter;
      return [filter];
    }
    const { filtersFlow = {} } = this.params;
    const { filters = {} } = requestData;

    const requestInstance = new ApiAuthService();

    return requestInstance.createGet<TableCrudResource<any>, TableCrudRequestDto>(this.params.path, {
      page: requestData.page,
      per_page: requestData.perPage,
      filters: Object.keys(filters).map((column) => ({
        column,
        values: getFilterValues(filters[column]),
        flow: filtersFlow[column] || FilterFlow.INCLUDE,
      })),
      search: requestData.search,
      sorting_order: requestData.sortOrder?.order,
      sorting_column: requestData.sortOrder?.column,
    }).send().then(({ data }) => {
      if (this.nextRequestData) {
        const nextData = this.nextRequestData;
        this.nextRequestData = undefined
        return this.requestTableData(nextData);
      }

      this.loading.value = false;
      this.data.value = data.rows;
      this.paginationData.value = {
        page: data.paginationDetails.page,
        perPage: data.paginationDetails.perPage,
        pageCount: data.paginationDetails.pageCount,
        rowsCount: data.paginationDetails.rowsCount,
      };
      this.filters.value = filters;
      this.search.value = requestData.search || '';
      this.sortOrder.value = requestData.sortOrder;

      return data;
    });
  }

  request(requestData?: TableDataRequestBody) {
    const data = {
      ...this.generateRequestData(),
      ...requestData,
    };

    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    if (this.loading.value) {
      this.nextRequestData = data;
    } else {
      this.requestTableData(data);
    }
  }

  debounceRequest(requestData?: TableDataRequestBody) {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(() => {
      this.debounceTimer = undefined;
      this.request(requestData);
    }, DEBOUNCE_TIMEOUT);
  }

  setPage(page: number) {
    this.request({
      page,
    });
  }

  setPagination(details?: PaginationDetails) {
    this.request({
      page: details?.page,
      perPage: details?.perPage,
    });
  }

  setFilters(filters?: Filters) {
    this.filters.value = filters;
    this.request();
  }

  setSortOrder(order?: SortDetails) {
    this.sortOrder.value = order;
    this.request();
  }

  setSearch(search: string) {
    this.search.value = search;
    this.debounceRequest();
  }
}
