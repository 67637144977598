import { onMounted } from 'vue';
import { useCategoriesStore } from '../dictionary/categories';
import { useCarBrandsStore } from '../dictionary/car-brands';
import { useCarModelsStore } from '../dictionary/car-models';
import { useRepairTypesStore } from '../dictionary/repair-types';
import { useItemCompaniesStore } from '../dictionary/item-companies';

export function initDictionary() {
  const categoriesStore = useCategoriesStore();
  const carBrandsStore = useCarBrandsStore();
  const carModelsStore = useCarModelsStore();
  const repairTypesStore = useRepairTypesStore();
  const itemCompaniesStore = useItemCompaniesStore();

  onMounted(() => {
    categoriesStore.initData();
    carBrandsStore.initData();
    carModelsStore.initData();
    repairTypesStore.initData();
    itemCompaniesStore.initData();
  });
}
