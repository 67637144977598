import { Option, SelectDataManager } from '../types';
import { computed, Ref, ref } from 'vue';
import { ApiAuthService } from '@/services/api-auth-service';
import TableCrudResource from '@shared/resources/table-crud.resource';
import { BaseResource } from '@shared/resources/base.resource';
import { TableCrudRequestDto } from '@shared/dto/table-crud-request.dto';
import { Filter } from '@shared/types/filter';

export interface SelectServerDataManagerProps {
  path: string;
  perPage?: number;
  filters?: Ref<Record<string, any>>
}
export function useSelectServerDataManager<Resource extends BaseResource = BaseResource>(params: SelectServerDataManagerProps): SelectDataManager {
  const searchValue = ref<string>('');
  const { perPage = 30, path } = params;

  const computedFilters = computed<Filter[]>(() => {
    const filters = params.filters?.value || {};

    return Object.keys(filters).reduce((res, key) => {
      const value = filters[key];
      if (Array.isArray(value)) {
        res.push({
          column: key,
          values: value,
        });
      } else if (!!value) {
        res.push({
          column: key,
          values: [value],
        });
      }
      return res;
    }, [] as Filter[]);
  });

  const requestInstance = new ApiAuthService();
  async function fetchNextOptions(): Promise<Option[]> {
    const response = await requestInstance.createGet<TableCrudResource<Resource>, TableCrudRequestDto>(path, {
      page: 1,
      per_page: perPage,
      search: searchValue.value.trim(),
      filters: computedFilters.value,
    }).send();

    return response.data.rows;
  }

  return {
    searchValue,
    fetchNextOptions,
  };
}
