import { Lang } from './messages';

export const LANG_LOCAL_STORAGE_KEY = 'LANG';
const acceptedLang = Object.values(Lang);

export function loadLang(defaultLang: Lang = Lang.EN) {
  const value = window.localStorage.getItem(LANG_LOCAL_STORAGE_KEY) as Lang | null;
  if (value && acceptedLang.includes(value)) {
    return value;
  }
  return defaultLang;
}
export function saveLang(lang: Lang) {
  window.localStorage.setItem(LANG_LOCAL_STORAGE_KEY, lang);
}
