import { ApiAuthService } from './api-auth-service';

export class FilesService extends ApiAuthService {
  protected pathPrefix(): string {
    return 'files';
  }

  public upload(file: File) {
    const data = new FormData();
    data.append('file', file);
    return this.createPost<number>('', data);
  }

  public download(id: number) {
    return this.createGet<Blob>(`${id}`, {}, {
      responseType: 'blob'
    });
  }

  public delete(id: number) {
    return this.createDelete(`${id}`);
  }
}
