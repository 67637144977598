import { cloneDeep } from 'lodash';

export function convertEmptyStringToNull<T extends Record<string, any>>(data: T, exceptions: string[] = []): Record<keyof T, any> {
  let result: Record<keyof T, any> = cloneDeep(data);
  for (const key in data) {
    const value = data[key];
    if (typeof value === 'string' && !exceptions.includes(key)) {
      const trimmed = value.trim();
      result[key] = trimmed.length === 0 ? null : trimmed;
    }
  }
  return result;
}

export function convertUndefinedToNull<T extends Record<string, any>>(data: T, exceptions: string[] = []): Record<keyof T, any> {
  let result: Record<keyof T, any> = cloneDeep(data);
  for (const key in data) {
    const value = data[key];
    if (value === undefined && !exceptions.includes(key)) {
      result[key] = null;
    }
  }
  return result;
}

export function convertToNull<T extends Record<string, any>>(data: T, exceptions: string[] = []): Record<keyof T, any> {
  return convertUndefinedToNull(convertEmptyStringToNull(data, exceptions), exceptions);
}
