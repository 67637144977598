import en from './translations/en.json';
import ua from './translations/ua.json';

export enum Lang {
  EN = 'en',
  UA = 'ua',
}

const messages = {
  en,
  ua,
};

export default messages;
