export enum OrderType {
  ORDER = 1,
  REPAIR = 2,
  REPAIR_ORDER = 3,
}

export const OrderTypeNameMap: Record<OrderType, string> = {
  [OrderType.ORDER]: 'order',
  [OrderType.REPAIR]: 'repair',
  [OrderType.REPAIR_ORDER]: 'repair_order',
};

export function isOrder(type: OrderType) {
  return [OrderType.ORDER, OrderType.REPAIR_ORDER].includes(type);
}
export function isRepair(type: OrderType) {
  return [OrderType.REPAIR, OrderType.REPAIR_ORDER].includes(type);
}

export const OrderTypeList = [OrderType.ORDER, OrderType.REPAIR, OrderType.REPAIR_ORDER];
