export default {
  // auth
  LOGIN: 'page-login',
  REGISTER: 'page-register',
  // app
  DASHBOARD: 'page-dashboard',
  CATEGORIES: 'page-categories',
  ITEM_COMPANIES: 'page-item-companies',
  REPAIR_TYPES: 'page-repair-types',
  ITEMS: 'page-items',
  CLIENTS: 'page-clients',
  CLIENT_CARS: 'page-client-cars',
  CAR_BRANDS: 'page-car-brands',
  CAR_MODELS: 'page-car-models',
  ORDERS: 'page-orders',
};
