import { OrderTypeList, OrderType } from '@shared/types/order-type';
import * as Joi from 'joi';
import { dateFormat, dateMaxNow } from '@shared/validation/helpers/custom-date-validation';
import { MAX_DECIMAL } from '@shared/constants/max-decimal';

export const orderCreateUpdateSchema = Joi.object({
  type: Joi.number().required().allow(...OrderTypeList),
  is_draft: Joi.boolean(),
  date: Joi.string().custom(dateFormat).custom(dateMaxNow).allow(null, ''),
  notes: Joi.string().allow(null, '').max(2000),
  items: Joi.array().allow(null).items(Joi.object({
    id: Joi.number().required(),
    qty: Joi.number().unsafe().max(MAX_DECIMAL).greater(0).required(),
    price: Joi.number().unsafe().max(MAX_DECIMAL).greater(0).required(),
  })),
  repair_items: Joi.array().allow(null).items(Joi.object({
    id: Joi.number().required(),
    qty: Joi.number().unsafe().max(MAX_DECIMAL).greater(0).required(),
    price: Joi.number().unsafe().max(MAX_DECIMAL).greater(0).required(),
  })),
}).when(Joi.object({
  is_draft: Joi.valid(false),
}), {
  then: Joi.object({
    client_id: Joi.number().required(),
    car_id: Joi.number().when('type', {
      is: Joi.valid(OrderType.REPAIR, OrderType.REPAIR_ORDER),
      then: Joi.number().required(),
      otherwise: Joi.number().allow(null),
    }).required(),
    items: Joi.when('type', {
      is: Joi.valid(OrderType.ORDER, OrderType.REPAIR_ORDER),
      then: Joi.array().min(1),
    }),
    repair_items: Joi.when('type', {
      is: Joi.valid(OrderType.REPAIR, OrderType.REPAIR_ORDER),
      then: Joi.array().min(1),
    }),
  }),
  otherwise: Joi.object({
    client_id: Joi.number().allow(null),
    car_id: Joi.number().allow(null),
  }),
});
