import * as Joi from 'joi';
import { ClientType } from '../../types/client-type';

export const clientCreateUpdateSchema = Joi.object({
  type: Joi.number().required().allow(ClientType.person, ClientType.company),
  phone: Joi.string().max(40).allow('', null),
  address: Joi.string().max(255).allow('', null),
  email: Joi.string().email({ tlds: { allow: false } }).max(255).allow('', null),
  notes: Joi.string().max(2000).allow('', null),
}).when(Joi.object({
  type: Joi.valid(ClientType.person),
}), {
  then: Joi.object({
    first_name: Joi.string().min(2).max(255).required(),
    last_name: Joi.string().min(2).max(255).required(),
  }),
  otherwise: Joi.object({
    name: Joi.string().min(2).max(500).required(),
  }),
});
