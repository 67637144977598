import { computed, ref } from 'vue';
import { Row } from '../../types';

export const useResponsiveExpanded = () => {
  const responsiveExpanded = ref<string | number | undefined>();
  const toggleResponsive = (row: Row) => {
    if (responsiveExpanded.value === row.id) {
      responsiveExpanded.value = undefined;
    } else {
      responsiveExpanded.value = row.id;
    }
  };

  return {
    responsiveExpanded,
    toggleResponsive,
  };
};
