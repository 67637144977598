import { Directive } from 'vue';

function isChildOrEqual(parent: HTMLElement, child: HTMLElement) {
  if (parent === child) return true;
  let el = child.parentElement;
  while(el) {
    if (el === parent) return true;
    el = el.parentElement;
  }
  return false;
}

interface OutsideClickTarget extends HTMLElement {
  _outsideClickHandler(e: MouseEvent|TouchEvent): void,
}
type Handler = () => void;
export interface OutsideClickConfig {
  additionalElements?(): HTMLElement[];
  handler: Handler;
}

export const outsideClick: Directive<OutsideClickTarget, OutsideClickConfig|Handler> = {
  mounted(el, {value, modifiers}) {
    function getElements(): HTMLElement[] {
      if (typeof value === 'function') {
        return [el];
      }
      const additional = value.additionalElements ? value.additionalElements() : [];
      return [el, ...additional];
    }
    const handler = typeof value === 'function' ? value : value.handler;

    const eventHandler = (e: MouseEvent|TouchEvent) => {
      const target = e.target as HTMLElement;

      if (!getElements().some((element) => isChildOrEqual(element, target))){
        handler();
      }
    }

    if (modifiers.immediate) {
      addEventListener('mousedown', eventHandler);
    } else {
      addEventListener('click', eventHandler);
    }
    el._outsideClickHandler = eventHandler;
  },
  beforeUnmount(el, {modifiers}) {
    if (modifiers.immediate) {
      removeEventListener('mousedown', el._outsideClickHandler);
    } else {
      removeEventListener('click', el._outsideClickHandler);
    }
  }
}
