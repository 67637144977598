import { Schema, ValidationErrorItem } from 'joi';
import { HandlerFunction } from './types';
import { nextTick, ref, Ref, watch, WatchStopHandle } from 'vue';
import { errorItemsDiff, errorItemsIntersection, errorItemsMerge } from './helpers';

interface _ValidateParams {
  unhandledErrors?: ValidationErrorItem[],
  disableInternalValidation?: boolean,
  errors?: ValidationErrorItem[],
}
export interface ValidatorParams {
  schema?: Ref<Schema>;
  data?: Ref<Record<string, any>>;
}

export default class Validator {
  private readonly schema: ValidatorParams['schema'];
  private readonly data: ValidatorParams['data'];

  private readonly errors: Ref<ValidationErrorItem[]>;
  private handlers: HandlerFunction[];
  private children: Validator[];
  private stopLiveValidationHandler?: WatchStopHandle;

  constructor(params: ValidatorParams = {}) {
    this.schema = params.schema;
    this.data = params.data;

    this.errors = ref([]);
    this.handlers = [];
    this.children = [];
  }

  addHandler(handler: HandlerFunction){
    if (!this.handlers.some((h) => h === handler)){
      this.handlers.push(handler);
      this.stopLiveValidationHandler && this.liveValidate();
    }
  }
  removeHandler(handler: HandlerFunction){
    this.handlers = this.handlers.filter((h) => h !== handler);
    this.stopLiveValidationHandler && this.liveValidate();
  }

  addChild(child: Validator){
    if (!this.children.some((c) => c === child)){
      this.children.push(child);
    }
  }
  removeChild(child: Validator){
    this.children = this.children.filter((c) => c !== child);
  }

  hasErrors(){
    return this.errors.value.length > 0;
  }
  getErrors(){
    return this.errors;
  }
  setErrors(errors: ValidationErrorItem[]){
    this._validate({
      errors,
      disableInternalValidation: true,
    });
  }

  private liveValidate(){
    this._validate();
    return this.errors.value.length === 0;
  }
  validate(){
    this._validate();
    if (this.hasErrors()){
      this.enableLiveValidation();
    } else {
      this.disableLiveValidation();
    }
    return this.errors.value.length === 0;
  }
  enableLiveValidation(){
    if (this.data?.value && this.stopLiveValidationHandler === undefined){
      this.stopLiveValidationHandler = watch(this.data, () => {
        nextTick(() => {
          this.liveValidate();
        })
      }, {
        deep: true,
      });
    }
  }
  disableLiveValidation(){
    if (this.stopLiveValidationHandler){
      this.stopLiveValidationHandler();
      this.stopLiveValidationHandler = undefined;
    }
  }

  private joiValidate(): ValidationErrorItem[] {
    if (this.schema?.value && this.data?.value) {
      const { error } = this.schema.value.validate(this.data.value, {
        stripUnknown: true,
        allowUnknown: true,
        abortEarly: false,
      });

      if (error){
        return error.details;
      }
    }
    return [];
  }

  private _validate(params: _ValidateParams = {}){
    let errors: ValidationErrorItem[] = params.errors || [];
    let handledErrors: ValidationErrorItem[] = [];
    if (!params.disableInternalValidation){
      errors.push(...this.joiValidate());
    }

    let unhandledErrors = errorItemsMerge(...errors, ...(params.unhandledErrors || []));
    this.handlers.forEach((handler) => {
      const newUnhandledErrors = handler(unhandledErrors);
      handledErrors.push(...errorItemsDiff(unhandledErrors, newUnhandledErrors));
      unhandledErrors = newUnhandledErrors;
    });

    this.children.forEach((child) => {
      const childResult = child._validate({
        unhandledErrors,
        disableInternalValidation: params.disableInternalValidation,
      });
      const newUnhandledErrors = childResult.unhandledParentErrors;
      handledErrors.push(...errorItemsDiff(unhandledErrors, newUnhandledErrors));
      unhandledErrors = newUnhandledErrors;
      errors = errorItemsMerge(...errors, ...childResult.errors);
    });

    this.errors.value = errorItemsMerge(...handledErrors, ...errors);

    return {
      errors,
      unhandledErrors,
      unhandledParentErrors: errorItemsIntersection(params.unhandledErrors || [], unhandledErrors),
    }
  }
}
