import { defineStore } from 'pinia';
import { ref } from 'vue';
import { Currency } from '@shared/types/currency';

export interface CurrencyExchangeItem {
  id: Currency;
  value: number;
}

export const useCurrencyStore = defineStore('currencies', () => {
  const exchangeCurrencies = ref<CurrencyExchangeItem[]>([
    {
      id: Currency.USD,
      value: 40.35,
    }
  ]);
  function exchange(value: number, currency: Currency) {
    const exchangeCurrency = exchangeCurrencies.value.find(({id}) => id === currency);
    if (exchangeCurrency) {
      return exchangeCurrency.value * value;
    }
    return value;
  }

  return {
    exchange,
  };
});
