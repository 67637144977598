import { ApiService } from '../libs/api-service/api-service';
import { AuthLoginDto } from '@shared/dto/auth';
import { LoginResource } from '@shared/resources/auth';

export default class AuthService extends ApiService {
  protected pathPrefix(): string {
    return 'auth';
  }

  login(data: AuthLoginDto) {
    return this.createPost<LoginResource>('login', data);
  }
}
