import { Ref, ref, UnwrapRef, watch } from 'vue';

export function loadFromLocalStorage<T>(key: string, defaultValue: T): T {
  const value = localStorage.getItem(key);
  if (!value) return defaultValue;
  return JSON.parse(value) as T;
}
export function saveToLocalStorage(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function useLocalStorage<T = any>(key: string, defaultValue: T, saveOnChange: boolean = true): Ref<UnwrapRef<T>> {
  const value = ref<T>(loadFromLocalStorage<T>(key, defaultValue as T));

  if (saveOnChange) {
    watch(value, () => {
      saveToLocalStorage(key, value.value);
    });
  }

  return value;
}
