<template>
  <div class="auth-layout">
    <div class="auth-header">
      <div class="title">I-Car</div>
    </div>
    <div class="auth-body">
      <router-view/>
    </div>
    <div class="auth-footer">

    </div>
  </div>
</template>

<script>
export default {
  name: "AuthLayout"
}
</script>

<style scoped lang="scss">
.auth-layout {
  @apply flex flex-col min-h-screen w-full;

  .auth-body {
    @apply flex-grow;
    background: $gray-background;
  }
  .auth-header {
    @apply flex-shrink-0 flex items-center px-3;
    background: $primary;
    color: white;
    height: 4rem;

    .title {
      font-size: 2rem;
      font-weight: 600;
      letter-spacing: 3px;
      font-family: 'Kaushan Script', cursive;
    }
  }
  .auth-footer {
    @apply flex-shrink-0;

    background: $primary;
    color: white;
    height: 2.5rem;
  }
}
</style>
