import { inject } from 'vue';
import { MODAL_DEFINITION_KEY } from '../provider-injection-keys';

export function useModalDefinition() {
  const definition = inject(MODAL_DEFINITION_KEY, undefined);
  if (!definition){
    throw new Error('useModalDefinition allowed only within ModalProvider');
  }
  return definition;
}
