import { Router } from 'vue-router';
import metaKeys from '../meta-keys';
import routeNames from '../route-names';
import { useUserStore } from '@/stores/user';

export default function (router: Router) {
  const userStore = useUserStore();

  router.beforeEach((to, from) => {
    if (to.matched.some((route) => route.meta[metaKeys.AUTH_REQUIRED]) && !userStore.isAuth){
      return {
        name: routeNames.LOGIN,
        query: {
          backURL: to.path,
        },
      };
    }
  });
}
