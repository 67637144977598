import { inject } from 'vue';
import { MODAL_CONTROLS_KEY } from '../provider-injection-keys';

export function useModalControls() {
  const controls = inject(MODAL_CONTROLS_KEY, undefined);
  if (!controls){
    throw new Error('useModalControls allowed only within ModalProvider');
  }
  return controls;
}
