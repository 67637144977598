import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { UserResource } from '@shared/resources/user'
import { useClockStore } from './clock';
import { UserService } from '@/services/user.service';
import { AxiosError } from 'axios';
import { setToken } from '@/services/api-auth-service';

export interface UserState {
  user?: UserResource,
  accessToken?: {
    value: string,
    expires: number,
  }
}

export const localStorageAccessTokenKey = 'ACCESS_TOKEN_KEY';

export const useUserStore = defineStore('user', () => {
  const clockStore = useClockStore();

  const state = ref<UserState>({});
  const isAuth = computed(() => {
    const accessToken = state.value.accessToken;
    return accessToken && accessToken.expires > clockStore.state.now;
  });

  function saveToLocalStorage() {
    if (isAuth.value) {
      window.localStorage.setItem(localStorageAccessTokenKey, JSON.stringify(state.value.accessToken));
    }
  }
  function restoreFromLocalStorage() {
    try {
      const data = JSON.parse(window.localStorage.getItem(localStorageAccessTokenKey) || '');
      const { value, expires } = data;
      if (value && typeof value === 'string' && expires && typeof expires === 'number' && expires > Date.now()) {
        state.value.accessToken = {
          value,
          expires
        };
        setToken(state.value.accessToken?.value);
      }
    } catch(e) {}
  }
  function setData(user: UserResource, accessToken: UserState['accessToken']) {
    state.value = {
      user,
      accessToken,
    };
    setToken(state.value.accessToken?.value);
    saveToLocalStorage();
  }

  async function init() {
    restoreFromLocalStorage();
    if (isAuth.value) {
      const userService = new UserService();

      try {
        const response = await userService.getProfile().send();
        state.value.user = response.data;
      } catch (error) {
        if (error instanceof AxiosError) {
          const status = error.response?.status;
          if (status === 401) {
            state.value.accessToken = undefined;
            setToken(undefined);
          }
        }
      }
    }
  }

  return {
    state,
    isAuth,
    setData,
    init,
  };
});
