import { Column } from '../../types';
import { computed, ComputedRef, onMounted, ref, Ref, watchEffect } from 'vue';
import { RESPONSIVE_COLUMN_WIDTH, SELECT_COLUMN_WIDTH, EXPANDED_COLUMN_WIDTH } from '../constants';
import _, { parseInt } from 'lodash';

const RESPONSIVE_MIN_WIDTH = 100;
enum WIDTH_TYPES {
  auto, // arithmetic average in percent
  px, // pixel
  pc, // percent
}

interface ColumnWidthRaw {
  name: Column['name'],
  width?: Column['width'];
  minWidth?: Column['minWidth'];
  responsiveOrder?: Column['responsiveOrder'];
}
export type ReplaceColumnWidth <T extends ColumnWidthRaw> = Omit<T, 'width'> & { width: number };
type ReplaceColumnCalculation <T extends ColumnWidthRaw> = Omit<T, 'width' & 'responsiveOrder'> & {
  width: number,
  widthType: WIDTH_TYPES,
  responsiveOrder: number;
};

export interface UseResponsiveParams <T extends ColumnWidthRaw> {
  columns: T[];
  tableWidth: number;
  selectable?: boolean;
  expandable?: boolean;
}
export interface UseResponsiveResult <T extends ColumnWidthRaw>{
  widthColumns: Ref<ReplaceColumnWidth<T>[]>;
  showResponsive: Ref<boolean>;
}
export const useResponsive = <T extends ColumnWidthRaw> (params: UseResponsiveParams<T>): UseResponsiveResult<T> => {
  const widthColumns: Ref<ReplaceColumnWidth<T>[]> = ref([]);
  const showResponsive = ref<boolean>(false);

  const widthCalculationColumns: ComputedRef<ReplaceColumnCalculation<T>[]> = computed(() => {
    const orders: string[] = [...params.columns].sort(({responsiveOrder: a}, {responsiveOrder: b}) => {
      if (a === undefined && b === undefined) return 0;
      if (a === undefined) return 1;
      if (b === undefined) return -1;
      return a - b;
    }).map(({name}) => name);
    const getOrder = (col: T) => {
      const order = orders.findIndex((name) => col.name === name);
      return order + 1;
    }

    return params.columns.map((col) => {
      if (col.width === undefined) return {
        ...col,
        width: 0,
        widthType: WIDTH_TYPES.auto,
        responsiveOrder: getOrder(col),
      }
      if (typeof col.width === 'number') return {
        ...col,
        width: col.width,
        widthType: WIDTH_TYPES.px,
        responsiveOrder: getOrder(col),
      }
      const parsed = parseInt(col.width);
      if (isNaN(parsed)) return {
        ...col,
        width: 0,
        widthType: WIDTH_TYPES.auto,
        responsiveOrder: getOrder(col),
      }
      return {
        ...col,
        width: parsed,
        widthType: col.width.includes('px') ? WIDTH_TYPES.px : WIDTH_TYPES.pc,
        responsiveOrder: getOrder(col),
      }
    }) as ReplaceColumnCalculation<T>[];
  })

  const recalculate = () => {
    let tableWidth = params.tableWidth;
    if (params.selectable){
      tableWidth -= SELECT_COLUMN_WIDTH;
    }
    if (params.expandable){
      tableWidth -= EXPANDED_COLUMN_WIDTH;
    }

    let responsiveIndex = 0;
    showResponsive.value = false;
    while(true){
      const tableWidthResponsive = (params.expandable || responsiveIndex === 0)
        ? tableWidth
        : tableWidth - RESPONSIVE_COLUMN_WIDTH;

      const responsiveColumns = widthCalculationColumns.value.filter((col) => {
        return col.responsiveOrder < (params.columns.length - responsiveIndex) + 1;
      });

      const pixelColumnsWidth = responsiveColumns.reduce((sum, col) => {
        return col.widthType === WIDTH_TYPES.px ? sum + col.width : sum;
      }, 0);

      let relativeTableWidth = tableWidthResponsive - pixelColumnsWidth;

      let percentColumns = responsiveColumns.filter((col) => {
        return col.widthType === WIDTH_TYPES.pc || col.widthType === WIDTH_TYPES.auto;
      });

      let percentColumnsSum = percentColumns.reduce((sum, col) => {
        return col.widthType === WIDTH_TYPES.auto
          ? sum + (100 / percentColumns.length)
          : sum + col.width
      }, 0);

      interface MinWidthCalcResult {
        minWidthColumns: Array<{
          col: ReplaceColumnCalculation<T>,
          width: number,
        }>,
        percentColumns: ReplaceColumnCalculation<T>[]
      }

      let minWidthColumns: MinWidthCalcResult['minWidthColumns'] = [];
      const minWidthCalcStep = () => {
        const result = percentColumns.reduce((res, col) => {
          const width = col.widthType === WIDTH_TYPES.auto ? 100 / percentColumns.length : col.width;

          const pixelWidth = relativeTableWidth * (width / percentColumnsSum);
          const minWidth: number = col.minWidth || RESPONSIVE_MIN_WIDTH;

          if (pixelWidth < minWidth){
            res.minWidthColumns.push({ col, width: minWidth });
          } else {
            res.percentColumns.push(col);
          }
          return res;
        }, {
          percentColumns: [],
          minWidthColumns: [],
        } as MinWidthCalcResult);

        percentColumns = result.percentColumns;
        percentColumnsSum = percentColumns.reduce((sum, col) => {
          return col.widthType === WIDTH_TYPES.auto
            ? sum + (100 / percentColumns.length)
            : sum + col.width
        }, 0);

        if (result.minWidthColumns.length > 0) {
          minWidthColumns.push(...result.minWidthColumns);
          result.minWidthColumns.forEach(({ width }) => {
            relativeTableWidth -= width;
          });

          minWidthCalcStep();
        }
      }
      minWidthCalcStep();

      widthColumns.value = _.cloneDeep(params.columns).map((col) => {
        const responsiveColumn = responsiveColumns.find(({name}) => name === col.name);
        if (!responsiveColumn) {
          return {
            ...col,
            width: 0,
          };
        }

        const minWidthColumn = minWidthColumns.find(({ col: column }) => column.name === col.name);
        if (minWidthColumn){
          return {
            ...col,
            width: minWidthColumn.width,
          };
        }

        let width: number;
        if (responsiveColumn.widthType === WIDTH_TYPES.px){
          width = responsiveColumn.width;
        } else {
          const value = responsiveColumn.widthType === WIDTH_TYPES.auto
            ? 100 / percentColumns.length
            : responsiveColumn.width;
          width = relativeTableWidth * (value / percentColumnsSum);
        }

        return {
          ...col,
          width,
        }
      });

      const responsiveColumnsWidth = widthColumns.value.reduce((sum, col) => sum + col.width ,0);
      if (_.round(responsiveColumnsWidth, 0) <= _.round(tableWidthResponsive, 0) || responsiveIndex >= params.columns.length - 1){
        break;
      }

      if (!params.expandable) {
        showResponsive.value = true;
      }
      responsiveIndex += 1;
    }
  }

  watchEffect(recalculate);
  onMounted(recalculate);

  return {
    widthColumns,
    showResponsive,
  }
}


//argo 370 за док, до завтра будет готово
// 250 100
