export enum QtyUnit {
  un = 1,
  kg = 2,
  m = 3,
  l = 4,
}

export const QtyUnitNameMap: Record<QtyUnit, string> = {
  [QtyUnit.m]: 'm',
  [QtyUnit.kg]: 'kg',
  [QtyUnit.l]: 'l',
  [QtyUnit.un]: 'un',
}

export const QtyUnitList = [QtyUnit.un, QtyUnit.m, QtyUnit.kg, QtyUnit.l];
