import { CarResource } from '@shared/resources/car';
import { CarModelResource } from '@shared/resources/car-model';

export function getClientCarName(car?: CarResource | null, defaultValue = '-') {
  if (car) {
    const { car_model } = car;
    const { car_brand } = car_model;

    return [car_brand.name, car_model.name, car.registration_plate].filter(Boolean).join(' ');
  }
  return defaultValue;
}

export function getCarName(car?: CarModelResource | null, defaultValue = '-') {
  if (car) {
    const { car_brand } = car;
    return `${car_brand.name} ${car.name}`;
  }
  return defaultValue;
}
