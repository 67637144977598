import './assets/tailwind.css';
import './assets/_styles.scss';
import useFontawesome from './plugins/fontawesome';

import { createApp } from 'vue'
import i18n from '@/i18n';
import { createPinia } from 'pinia';
import App from './pages/App.vue'
import createRouter from './router'
import { useUserStore } from './stores/user';

const app = createApp(App);
useFontawesome(app);

app.use(i18n);

// order is important
// while creating router we need active pinia instance
// so pinia initialization must be before router initialization
const pinia = createPinia();
app.use(pinia);

const router = createRouter();
app.use(router);

const userStore = useUserStore();
userStore.init().finally(() => {
  app.mount('#app');
});
