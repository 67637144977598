import { RouteRecordRaw } from 'vue-router';
import { pathJoin } from '@/helpers/path';
import routeNames from './route-names';
import metaKeys from './meta-keys';
// layouts
import AuthLayout from '@/layouts/auth/AuthLayout.vue';
import AppLayout from '@/layouts/app/AppLayout.vue';
// pages
import PageLogin from '@/pages/auth/PageLogin.vue';
import PageRegister from '@/pages/auth/PageRegister.vue';
import PageDashboard from '../pages/app/page-dashboard/PageDashboard.vue';
import PageEmpty from '@/pages/app/PageEmpty.vue';

import PageCategories from '@/pages/app/dictionary/categories/PageCategories.vue';
import PageItemCompanies from '@/pages/app/dictionary/item-companies/PageItemCompanies.vue';
import PageRepairTypes from '@/pages/app/dictionary/repair-types/PageRepairTypes.vue';
import PageCarBrands from '@/pages/app/dictionary/car-brands/PageCarBrands.vue';
import PageCarModels from '@/pages/app/dictionary/car-models/PageCarModels.vue';
import PageItems from '@/pages/app/items/PageItems.vue';
import PageClients from '@/pages/app/clients/PageClients.vue';
import PageCars from '@/pages/app/clients/PageCars.vue';
import PageOrders from '@/pages/app/orders/PageOrders.vue';

const withPrefix = (prefix: string, routes: RouteRecordRaw[]): RouteRecordRaw[] => {
  return routes.map((route) => ({
    ...route,
    path: pathJoin(prefix, route.path),
  }));
}

export default [
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        path: 'login',
        name: routeNames.LOGIN,
        component: PageLogin,
      },
      {
        path: 'register',
        name: routeNames.REGISTER,
        component: PageRegister,
      },
    ],
  },
  {
    path: '/',
    component: AppLayout,
    meta: {
      [metaKeys.AUTH_REQUIRED]: true,
    },
    children: [
      {
        path: '',
        name: routeNames.DASHBOARD,
        component: PageDashboard,
      },
      {
        path: 'orders',
        name: routeNames.ORDERS,
        component: PageOrders,
      },
      {
       path: 'storage',
       name: routeNames.ITEMS,
       component: PageItems,
      },
      ...withPrefix('clients', [
        {
          path: '',
          name: routeNames.CLIENTS,
          component: PageClients
        },
        {
          path: 'cars',
          name: routeNames.CLIENT_CARS,
          component: PageCars,
        },
      ]),
      ...withPrefix('dictionary', [
        {
          path: 'categories',
          component: PageCategories,
          name: routeNames.CATEGORIES,
        },
        {
          path: 'item-companies',
          component: PageItemCompanies,
          name: routeNames.ITEM_COMPANIES,
        },
        {
          path: 'repair-types',
          component: PageRepairTypes,
          name: routeNames.REPAIR_TYPES,
        },
        {
          path: 'models',
          component: PageCarModels,
          name: routeNames.CAR_MODELS,
        },
        {
          path: 'brands',
          component: PageCarBrands,
          name: routeNames.CAR_BRANDS,
        },
      ]),
      {
        path: ':pathMatch(.*)*',
        component: PageEmpty,
      }
    ],
  },
];
