import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { App } from 'vue';

// @ts-ignore typing a little bit broken for fontawesome, i don't know why
// TODO fix typing
library.add(fas);

export default (app: App) => {
  app.component('FontAwesomeIcon', FontAwesomeIcon);
}
