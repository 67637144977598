import { coinsFormat } from '@shared/helpers/data-converters/number-converters';
import { timestampToDate } from '../../../helpers/datetime';

type DigitClass = [zero: string, one: string, two: string];

export function numberInWords(value: number, currency: [string, string] = ['грн.', 'коп.'], lang: string = 'ua') {
  // TODO: add for other langs

  const coins = coinsFormat(value);
  const [w, f] = coins.split('.');

  const splitNumberIntoSegments = (value: number) => {
    const valueString = String(value);
    const segments = [];
    for (let i = 0; i < Math.ceil(valueString.length / 3); ++i) {
      segments.push(valueString.slice(
        Math.max(0, valueString.length - ((i + 1) * 3)),
        valueString.length - (i * 3))
      );
    }
    return segments.reverse();
  }

  const getLastNumber = (value: number) => {
    const valueString = String(value);
    const last = valueString.slice(-1);
    return Number(last);
  }

  const selectClassForNumber = (digitClass: DigitClass, value: number) => {
    if (value > 19 || [1, 2, 3, 4].includes(value)) {
      const lastNumber = getLastNumber(value);
      if (lastNumber === 1) return digitClass[1];
      if ([2, 3, 4].includes(lastNumber)) return digitClass[2];
    }
    return digitClass[0];
  }

  const hundredsNames = [
    'сто',
    'двісті',
    'триста',
    'чотириста',
    'п’ятсот',
    'шістсот',
    'сімсот',
    'вісімсот',
    'дев’ятсот',
  ];
  const tensNames = [
    'двадцять',
    'тридцять',
    'сорок',
    'п’ятдесят',
    'шістдесят',
    'сімдесят',
    'вісімдесят',
    'дев’яносто',
  ];
  const unitsNames = [
    'один',
    'два',
    'три',
    'чотири',
    'п’ять',
    'шість',
    'сім',
    'вісім',
    'дев’ять',
    'десять',
    'одинадцять',
    'дванадцять',
    'тринадцять',
    'чотирнадцять',
    'п’ятнадцять',
    'шістнадцять',
    'сімнадцять',
    'вісімнадцять',
    'дев’ятнадцять',
  ];

  const digitClasses: DigitClass[] = [
    ['тисяч', 'тисяча', 'тисячі'],
    ['мільйонів', 'мільйон', 'мільйона'],
    ['мільярдів', 'мільярд', 'мільярда'],
    ['трильйонів', 'трильйон', 'трильйона'],
    ['квадрильйонів', 'квадрильйон', 'квадрильйона'],
    ['квінтильйонів', 'квінтильйон', 'квінтильйона'],
  ]

  const segments = splitNumberIntoSegments(Number(w));
  const mapped = segments.map((segment, index) => {
    const classIndex = segments.length - index; // 1

    let result = [];

    let hundreds = 0;
    if (segment.length === 3) {
      hundreds = Number(segment.slice(0, 1));
      if (hundreds > 0) {
        result.push(hundredsNames[hundreds - 1]);
      }
    }

    const units = segment.length === 3 ? Number(segment.slice(1)) : Number(segment);
    if (units > 0) {
      if (units <= 19) {
        if (classIndex <= 2 && units <= 2) {
          result.push(units === 1 ? 'одна' : 'дві')
        } else {
          result.push(unitsNames[units - 1]);
        }
      } else {
        const tens = Number(String(units).slice(0, 1));
        result.push(tensNames[tens - 2]);

        const unitsLast = Number(String(units).slice(1));
        if (unitsLast > 0) {
          if (classIndex <= 2 && unitsLast <= 2) {
            result.push(unitsLast === 1 ? 'одна' : 'дві')
          } else {
            result.push(unitsNames[unitsLast - 1]);
          }
        }
      }
    }

    if (classIndex > 1 && result.length > 0) {
      const units = segment.length === 3 ? Number(segment.slice(1)) : Number(segment);

      result.push(selectClassForNumber(digitClasses[classIndex - 2], units));
    }

    return result.join(' ');
  });

  const wPart = mapped.filter((value) => value.trim().length > 0).join(' ') + ' ' + currency[0];
  const fPart = f + ' ' + currency[1];
  return `${wPart} ${fPart}`;
}

export function dateInWords(date: number) {
  const days = [
    'першого',
    'другого',
    'третього',
    'четвертого',
    'п’ятого',
    'шостого',
    'сьомого',
    'восьмого',
    'дев’ятого',
    'десятого',
    'одинадцятого',
    'дванадцятого',
    'тринадцятого',
    'чотирнадцятого',
    'п’ятнадцятого',
    'шістнадцятого',
    'сімнадцятого',
    'вісімнадцятого',
    'дев’ятнадцятого',
    'двадцятого',
    'двадцять першого',
    'двадцять другого',
    'двадцять третього',
    'двадцять четвертого',
    'двадцять п’ятого',
    'двадцять шостого',
    'двадцять сьомого',
    'двадцять восьмого',
    'двадцять дев’ятого',
    'тридцятого',
    'тридцять першого',
  ];
  const months = [
    'січня',
    'лютого',
    'березня',
    'квітня',
    'травня',
    'червня',
    'липня',
    'серпня',
    'вересня',
    'жовтня',
    'листопада',
    'грудня',
  ];

  const dateObj = new Date(date);

  const day = dateObj.getUTCDate() - 1;
  const month = dateObj.getUTCMonth();
  const year = dateObj.getUTCFullYear();

  return `${days[day]} ${months[month]} ${year} року`;
}
