import { FilesService } from '@/services/files.service';
import { onBeforeUnmount, ref, Ref, watch } from 'vue';

const DELETE_TIMEOUT = 5000;

interface ImageRaw {
  blob: Blob,
  src: string,
}
interface Image {
  subscribesCount: number,
  data?: ImageRaw,
  promise: Promise<ImageRaw>,
  imageDeleteTimeout?: any,
}

const images: Map<number, Image> = new Map;
function downloadImage(id: number): Promise<ImageRaw> {
  const service = new FilesService();
  return service.download(id).send().then(({data}) => ({
    blob: data,
    src: URL.createObjectURL(data),
  }));
}

export function useImageController() {
  return {
    subscribe(id: number): Promise<ImageRaw> {
      const existedImage = images.get(id);
      if (existedImage){
        existedImage.subscribesCount += 1;
        if (existedImage.imageDeleteTimeout) {
          clearTimeout(existedImage.imageDeleteTimeout);
          existedImage.imageDeleteTimeout = undefined;
        }
        if (existedImage.data) {
          return Promise.resolve(existedImage.data);
        }
        return existedImage.promise;
      }

      let image: Image;
      const promise = new Promise<ImageRaw>(async (res) => {
        const imageRaw = await downloadImage(id);
        image.data = imageRaw;
        res(imageRaw);
      });
      image = {
        subscribesCount: 1,
        promise,
      };

      images.set(id, image);
      return promise;
    },
    unsubscribe(id: number) {
      const image = images.get(id);
      if (image) {
        image.subscribesCount -= 1;
        if (image.subscribesCount === 0) {
          image.imageDeleteTimeout = setTimeout(() => {
            images.delete(id);
          }, DELETE_TIMEOUT);
        }
      }
    }
  }
}

export function useImage(id: Ref<number|undefined>) {
  const {
    subscribe,
    unsubscribe,
  } = useImageController();

  const loading = ref<boolean>(false);
  const src = ref<string>();
  let currentLoadingIndex = 0;

  function watcherSubscribe(id: number, loadingIndex: number) {
    subscribe(id).then((image) => {
      if (loadingIndex !== currentLoadingIndex) {
        unsubscribe(id);
      } else {
        src.value = image.src;
      }
    }).finally(() => {
      if (loadingIndex === currentLoadingIndex) {
        loading.value = false;
      }
    })
  }
  watch(id,  (value, oldValue) => {
    src.value = undefined;
    !loading.value && oldValue && unsubscribe(oldValue);

    if (value) {
      loading.value = true;
      watcherSubscribe(value, ++currentLoadingIndex);
    } else {
      loading.value = false;
    }
  }, {
    immediate: true,
  });

  onBeforeUnmount(() => {
    id.value && unsubscribe(id.value);
  });

  return {
    src,
    loading,
  };
}
