import { ApiAuthService } from './api-auth-service';
import { UserResource } from '@shared/resources/user';

export class UserService extends ApiAuthService {
  protected pathPrefix(): string {
    return 'users';
  }

  getProfile(){
    return this.createGet<UserResource>('profile');
  }
}
