import { coinsFormat, toNumberFormat } from './data-converters/number-converters';

function get(obj: Record<string, any>, field: string, defaultValue: any = undefined): any {
  if (obj[field] !== undefined) return obj[field];
  return defaultValue;
}

export interface SumParams {
  qtyField?: string;
  priceField?: string;
  adjustment?: number;
}

export function countItemPrice(item: Record<string, any>, params: SumParams = {}) {
  const {
    adjustment = 0,
    qtyField = 'qty',
    priceField = 'price',
  } = params;

  const qty = toNumberFormat(get(item, qtyField, 0));
  const price = toNumberFormat(get(item, priceField, 0));

  let priceRound = Math.round(price * 100) / 100;
  if (adjustment !== 0) {
    priceRound = Math.round((priceRound + (priceRound * adjustment / 100)) * 10) / 10;
  }

  return Math.round(qty * priceRound * 100) / 100;
}
export function countSum(items: Array<Record<string, any>>, params: SumParams = {}) {
  return coinsFormat(items.reduce((sum, item) => sum + countItemPrice(item, params), 0));
}
