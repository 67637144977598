import { defineStore } from 'pinia';
import { Component, markRaw, nextTick, ref } from 'vue';
import { v4 } from 'uuid';

export interface ModalDefinition {
  id: string;
  component: Component;
  props?: Record<string, any>;
  events?: Record<string, (...args: any[]) => void>;
  onClose?: () => void;
  onOpen?: () => void;
  immediateClose?: boolean;
  immediateOpen?: boolean;
}
export interface ModalsStoreState {
  modals: ModalDefinition[]
}

export const useModalsStore = defineStore('modals', () => {
  const state = ref<ModalsStoreState>({
    modals: []
  });
  function addModal(definition: Omit<ModalDefinition, 'id'>) {
    const id: string = v4();
    state.value.modals.push({
      ...definition,
      id,
      component: markRaw(definition.component),
    });

    return {
      id,
      close: (immediate: boolean = false) => closeModal(id, immediate),
    };
  }
  async function closeModal(id: string, immediateClose: boolean = false) {
    state.value.modals = state.value.modals.map((modal) => {
      return modal.id === id ? { ...modal, immediateClose } : modal;
    });
    await nextTick();
    state.value.modals = state.value.modals.filter((modal) => modal.id !== id);
  }
  async function closeAllModals() {
    state.value.modals = state.value.modals.map((modal) => ({ ...modal, immediateClose: true }));
    await nextTick();
    state.value.modals = [];
  }

  return {
    state,
    addModal,
    closeModal,
  };
})
