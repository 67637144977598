import { CrudService } from './crud.service';
import { RepairOrderCreateUpdateDto as DTO } from '@shared/dto/repair-order';
import { RepairOrderResource as Res } from '@shared/resources/repair-order';

export class OrderService extends CrudService<DTO, DTO, Res> {
  protected pathPrefix(): string {
    return 'orders';
  }

  removeClosed(id: number) {
    return this.createDelete(`closed/${id}`);
  }

  closeOrder(id: number) {
    return this.createPost(`close/${id}`);
  }
}
