import { ValidationErrorItem } from 'joi';
import _ from 'lodash';

export const errorItemsComparison = (a: ValidationErrorItem, b: ValidationErrorItem) => {
  return a.path.join('.') === b.path.join('.') && a.type === a.type;
};

export const errorItemsMerge = (...items: ValidationErrorItem[]) => {
  let result: ValidationErrorItem[] = [];
  items.forEach((item) => {
    if (!result.some((rItem) => errorItemsComparison(rItem, item))) {
      result.push(item);
    }
  });
  return _.cloneDeep(result);
};

export const errorItemsIntersection = (a: ValidationErrorItem[], b: ValidationErrorItem[]) => {
  let result: ValidationErrorItem[] = [];
  a.forEach((aItem) => {
    b.forEach((bItem) => {
      if (errorItemsComparison(aItem, bItem)) {
        result.push(aItem);
      }
    });
  });
  return _.cloneDeep(result);
};

export const errorItemsDiff = (a: ValidationErrorItem[], b: ValidationErrorItem[]) => {
  return _.cloneDeep(a).filter((aItem) => {
    return !b.some((bItem) => errorItemsComparison(aItem, bItem));
  });
};

export const errorItemsQuery = (errors: ValidationErrorItem[], query: Array<string|number>, nested: boolean =  false): ValidationErrorItem[] => {
  const keyComparison = (key: string, queryKey: string) => {
    return queryKey === '*' || key === queryKey;
  }
  return errors.filter((error) => {
    if ((!nested && error.path.length !== query.length) || query.length > error.path.length) return false;
    return !query.some((item, index) => !keyComparison(String(error.path[index]), String(item)))
  })
}
