import { HandlerFunction } from '../types';
import { inject, onBeforeUnmount, Ref, watch } from 'vue';
import { validatorKey } from '../injection-keys';

export const useHandler = (handlerFunction: Ref<HandlerFunction>) => {
  const validator = inject(validatorKey, undefined);
  if (validator){
    validator.addHandler(handlerFunction.value);
    onBeforeUnmount(() => validator.removeHandler(handlerFunction.value))

    watch(handlerFunction, (value, oldValue) => {
      validator.removeHandler(oldValue);
      validator.addHandler(value);
    });
  }
}
