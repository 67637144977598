import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes';
import createAuthHook from './hooks/auth-hook';

export default function() {
  const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  createAuthHook(router);

  return router;
}
