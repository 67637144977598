export enum Currency {
  UAH = 1,
  USD = 2,
}

export const CurrencyNameMap = {
  [Currency.UAH]: 'UAH',
  [Currency.USD]: 'USD',
};

export const CurrencyList = [
  Currency.UAH,
  Currency.USD,
];

export const CurrencySymbols = {
  [Currency.UAH]: '₴',
  [Currency.USD]: '$',
};
