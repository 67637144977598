import { useHandler } from './use-handler';
import { errorItemsDiff, errorItemsQuery } from '../helpers';
import { computed, reactive, Ref, ref } from 'vue';
import { ValidationErrorItem, isRef } from 'joi';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';

export interface UseKeyHandlerParams {
  keys: Array<string | number>;
  nested: boolean,
}
export const useKeyHandler = (params: Ref<UseKeyHandlerParams>) => {
  const { t } = useI18n();

  const errors = ref<ValidationErrorItem[]>([]);
  const hasErrors = computed(() => errors.value.length > 0);
  const firstError = computed<ValidationErrorItem | undefined>(() => errors.value[0]);
  const errorMessages = computed(() => {
    return errors.value.reduce((messages, error) => {
      const { context = {} } = error;
      const computedContext: Record<string, any> = {};
      for(const key in context) {
        const value = context[key];
        if (isRef(value)) {
          computedContext[key] = t(`fields.${value.key}`);
        } else {
          computedContext[key] = value;
        }
      }
      const message = t(`validation.${error.type}`, computedContext);
      messages.push(message);
      return messages;
    }, [] as string[]);
  });
  const firstErrorMessage = computed(() => errorMessages.value[0] || '');

  useHandler(computed(() => (er: ValidationErrorItem[]) => {
    const handled = errorItemsQuery(er, params.value.keys, params.value.nested);
    errors.value = _.cloneDeep(handled);
    return errorItemsDiff(er, handled);
  }));

  return reactive({
    errors,
    hasErrors,
    firstError,
    errorMessages,
    firstErrorMessage,
  })
}
