export enum NAVIGATION_TYPES {
  node = 0,
  button = 1
}

export interface NavigationNode {
  name: string,
  path?: string,
  exact?: boolean,
  action?: () => void|Promise<void>,
  label: string,
  nodes?: Array<NavigationNode | NavigationButton>,
  type: NAVIGATION_TYPES.node,
  icon?: string,
}

export interface NavigationNodeRender extends NavigationNode{
  isActive: boolean,
  fullPath: string,
  nodes: Array<NavigationNodeRender | NavigationButton>,
}

export interface NavigationButton {
  type: NAVIGATION_TYPES.button,
  name: string,
  label: string,
  icon?: string,
  action: () => void|Promise<void>,
}