import { AxiosRequestCallback } from './types';
import Validator from '@/libs/validator/validator';
import { AxiosError } from 'axios';
import UnprocessableEntityCodes from '@shared/errors_codes/unprocessable-entity';

export interface ApiRequestParams<Response = any>{
  axiosRequestCallback: AxiosRequestCallback<Response>;
}

export class ApiRequest<Response = any>{
  private readonly axiosRequestCallback: AxiosRequestCallback<Response>;
  private validator?: Validator;

  constructor(params: ApiRequestParams) {
    this.axiosRequestCallback = params.axiosRequestCallback;
  }

  useValidator(validator?: Validator) {
    this.validator = validator;
    return this;
  }

  send(){
    const promise = this.axiosRequestCallback();
    const validator = this.validator;
    if (validator) {
      promise.catch((e) => {
        if (e instanceof AxiosError) {
          const { status, data } = e.response || {};
          if (status === 422 && data.code === UnprocessableEntityCodes.VALIDATION) {
            validator.setErrors([data.details]);
            return;
          }
        }
        throw e;
      });
    }
    return promise;
  }
}
