import { ApiAuthService } from './api-auth-service';
import { BaseResource } from '@shared/resources/base.resource';

export class CrudService<CreateDTO, UpdateDTO, Resource extends BaseResource> extends ApiAuthService {
  getAll() {
    return this.createGet<Resource[]>('')
  }

  getById(id: string) {
    return this.createGet<Resource>(`${id}`)
  }

  add(data: CreateDTO) {
    return this.createPost<Resource>('', data);
  }

  update(id: number, data: UpdateDTO) {
    return this.createPut<Resource>(`${id}`, data);
  }

  remove(id: number) {
    return this.createDelete(`${id}`);
  }
}
